<template>
  <div>
    <!-- 头部 -->
    <Header></Header>
    <!-- 大图 -->
    <div class="dt">
      <div class="dt_text">
        <div class="text1">免费 · 稳定 · 高效</div>
        <div class="text2">商品一键建档，数据轻松管理</div>
        <div class="text3">多行业 | 跨平台 | 云端同步 | 免费试用</div>
      </div>
    </div>

    <!-- 产品功能 -->
    <div class="cpgn">
      <div class="cpgn_title">产品功能</div>
      <div class="cpgn_subtitle">彼思系统双端联动</div>
      <img
        class="cpgn_img_left"
        src="http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgn1.jpg"
        alt=""
      />
      <img
        class="cpgn_img_right"
        src="http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgn2.jpg"
        alt=""
      />
    </div>

    <!-- 功能图标 -->
    <div class="gntb clearfix">
      <ul v-for="item of gntb" :key="item.index">
        <li>
          <p><img :src="item.url" alt="" /></p>
          {{ item.title }}
        </li>
      </ul>
    </div>

    <!-- 会员管理 -->
    <div class="hygl">
      <div class="hygl_title">会员管理，顾客一批接一批的来</div>
      <div class="hygl_subtitle">智能会员 CRM 提升客户粘度</div>
    </div>

    <!-- 聚合支付 -->
    <div class="jhzf">
      <div class="jhzf_title">聚合支付 收银便捷</div>
      <div class="jhzf_subtitle">移动支付全面接入，收银无忧</div>
      <img
        src="http://www.zhonglunnet.com/templets/zlnet/images/cppaytype.jpg"
        alt=""
      />
    </div>

    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "retail",
  data() {
    return {
      gntb: [
        {
          index: 1,
          title: "商品管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon01.png",
        },
        {
          index: 2,
          title: "销售管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon02.png",
        },
        {
          index: 3,
          title: "采购管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon03.png",
        },
        {
          index: 4,
          title: "库存管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon04.png",
        },
        {
          index: 5,
          title: "会员管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon05.png",
        },
        {
          index: 6,
          title: "结算管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon06.png",
        },
        {
          index: 7,
          title: "批发管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon07.png",
        },
        {
          index: 8,
          title: "广告管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon08.png",
        },
        {
          index: 9,
          title: "微商城",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon09.png",
        },
        {
          index: 10,
          title: "促销管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon10.png",
        },
        {
          index: 11,
          title: "配送管理",
          url:
            "http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/cpgnicon11.png",
        },
      ],
    };
  },
  components: { Header, Footer },
};
</script>

<style scoped>
/* 大图 */
.dt {
  height: 545px;
  width: 100%;
  background-image: url("http://14.116.154.181:8890/bisi_website/gw/banner2.jpg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
}
.dt_text {
  margin: 0 auto;
  width: 1100px;
  height: 545px;
  text-align: left;
}
.text1 {
  padding: 163px 0px 30px 0px;
  font-size: 55px;
  color: #ffffff;
}
.text2 {
  font-weight: 700;
  padding-bottom: 20px;
  font-size: 30px;
  color: #ffffff;
}
.text3 {
  font-size: 22px;
  color: #ffffff;
}

/* 产品功能 */
.cpgn {
  width: 1100px;
  margin: 0px auto;
}
.cpgn_title {
  padding-top: 40px;
  font-size: 30px;
  color: #293a6c;
}
.cpgn_subtitle {
  color: #b6b6b9;
  padding: 15px 0px 50px 0px;
}
.cpgn_img_left {
  width: 420px;
  height: 400px;
  margin-right: 120px;
}
.cpgn_img_right {
  width: 420px;
  height: 400px;
  margin-left: 80px;
}

/* 功能图标 */
.gntb {
  width: 1100px;
  height: 450px;
  margin: 0px auto;
}
.gntb ul li {
  width: 106px;
  height: 106px;
  margin: 28px;
  list-style: none;
  float: left;
  text-align: center;
  margin-left: 36px;
}
.gntb ul li p {
  background: #ffffff;
  width: 106px;
  height: 106px;
  margin-bottom: 20px;
  box-shadow: 0px 0px 20px 0px rgba(65, 146, 239, 0.22);
}

.gntb ul li img {
  margin-top: 23px;
  width: 60px;
  height: 60px;
}

/* 会员管理 */
.hygl {
  background-image: url(http://www.zhonglunnet.com/templets/zlnet/images/chanpin/index/hyglbanner.jpg);
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top center;
  height: 700px;
  text-align: center;
}

.hygl_title {
  position: relative;
  color: #293a6c;
  font-size: 30px;
  top: 50px;
}
.hygl_subtitle {
  position: relative;
  color: #b6b6b9;
  font-size: 15px;
  top: 70px;
}

/* 聚合支付 */
.jhzf {
  width: 10100;
  height: 450px;
  margin: 0px auto;
}

.jhzf_title {
  padding: 50px 0px 10px 0px;
  height: 50px;
  color: #293a6c;
  font-size: 30px;
}
.jhzf_subtitle {
  padding-bottom: 70px;
  color: #b6b6b9;
  font-size: 15px;
}

/* 消除浮点 */
.clearfix {
  *zoom: 1;
}

.clearfix:after {
  content: "clear";
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
  visibility: hidden;
}
</style>